import { ChakraProvider } from "@chakra-ui/react";
import PlausibleProvider from "next-plausible";
import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { publicConfig } from "@/config.public";
import theme from "../theme/index";
import LogoProvider from "./contextLogo";
import WidgetProvider from "./contextWidget";
import DisplayContextProvider from "./DisplayContextProvider";
import ParameterContextProvider from "./ParameterContextProvider";
import SearchResultContextProvider from "./SearchResultContextProvider";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});
const Providers = ({
  children
}) => {
  return <ChakraProvider theme={theme} data-sentry-element="ChakraProvider" data-sentry-component="Providers" data-sentry-source-file="Providers.tsx">
      <PlausibleProvider domain={publicConfig.plausibleDomain} trackOutboundLinks={true} trackLocalhost={true} enabled={true} data-sentry-element="PlausibleProvider" data-sentry-source-file="Providers.tsx">
        <SearchResultContextProvider data-sentry-element="SearchResultContextProvider" data-sentry-source-file="Providers.tsx">
          <ParameterContextProvider data-sentry-element="ParameterContextProvider" data-sentry-source-file="Providers.tsx">
            <DisplayContextProvider data-sentry-element="DisplayContextProvider" data-sentry-source-file="Providers.tsx">
              <WidgetProvider data-sentry-element="WidgetProvider" data-sentry-source-file="Providers.tsx">
                <LogoProvider data-sentry-element="LogoProvider" data-sentry-source-file="Providers.tsx">
                  <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-source-file="Providers.tsx">{children}</QueryClientProvider>
                </LogoProvider>
              </WidgetProvider>
            </DisplayContextProvider>
          </ParameterContextProvider>
        </SearchResultContextProvider>
      </PlausibleProvider>
    </ChakraProvider>;
};
export default Providers;