import { Container, Divider, Grid, GridItem, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import Image from "next/image";
import React from "react";
import TagCandidatureSpontanee from "../ItemDetail/TagCandidatureSpontanee";
import TagOffreEmploi from "../ItemDetail/TagOffreEmploi";
const AlgoHome = () => {
  return <Container variant="responsiveContainer" data-sentry-element="Container" data-sentry-component="AlgoHome" data-sentry-source-file="AlgoHome.tsx">
      <Grid templateColumns={{
      base: "1fr",
      md: "repeat(12, 1fr)"
    }} data-sentry-element="Grid" data-sentry-source-file="AlgoHome.tsx">
        <GridItem colSpan={8} pr="8" data-sentry-element="GridItem" data-sentry-source-file="AlgoHome.tsx">
          <Text as="h2" data-sentry-element="Text" data-sentry-source-file="AlgoHome.tsx">
            <Text as="span" display="block" mb={1} variant="editorialContentH1" color="#2a2a2a" data-sentry-element="Text" data-sentry-source-file="AlgoHome.tsx">
              Vous révéler
            </Text>
            <Text as="span" display="block" mb={1} variant="editorialContentH1" data-sentry-element="Text" data-sentry-source-file="AlgoHome.tsx">
              le marché caché de l&apos;emploi
            </Text>
          </Text>
          <Divider variant="pageTitleDivider" my={12} data-sentry-element="Divider" data-sentry-source-file="AlgoHome.tsx" />
          <Text as="p" fontSize="18px" mb="5" data-sentry-element="Text" data-sentry-source-file="AlgoHome.tsx">
            La bonne alternance expose différents types d&apos;opportunités d&apos;emplois :
          </Text>
          <UnorderedList data-sentry-element="UnorderedList" data-sentry-source-file="AlgoHome.tsx">
            <ListItem data-sentry-element="ListItem" data-sentry-source-file="AlgoHome.tsx">
              <Text as="p" fontSize="18px" mb="5" data-sentry-element="Text" data-sentry-source-file="AlgoHome.tsx">
                <strong>Les offres d&apos;emploi</strong> : publiées sur notre plateforme ainsi que celles issues de France Travail et ses partenaires. Elles sont identifiées grâce
                au tag <TagOffreEmploi data-sentry-element="TagOffreEmploi" data-sentry-source-file="AlgoHome.tsx" />
              </Text>
            </ListItem>
            <ListItem data-sentry-element="ListItem" data-sentry-source-file="AlgoHome.tsx">
              <Text as="p" fontSize="18px" mb="5" data-sentry-element="Text" data-sentry-source-file="AlgoHome.tsx">
                <strong>Les candidatures spontanées</strong> : correspondant au marché caché de l&apos;emploi. Grâce à l'analyse de diverses données publiques (données de
                recrutement, données financières, etc.), La bonne alternance identifie chaque mois une liste restreinte d'entreprises à fort potentiel d'embauche en alternance,
                afin de faciliter les démarches de candidatures spontanées de ses utilisateurs. Elles sont identifiées grâce au tag <TagCandidatureSpontanee data-sentry-element="TagCandidatureSpontanee" data-sentry-source-file="AlgoHome.tsx" />
              </Text>
            </ListItem>
          </UnorderedList>
        </GridItem>
        <GridItem colSpan={4} display="flex" justifyContent="center" alignItems="center" pt="5" data-sentry-element="GridItem" data-sentry-source-file="AlgoHome.tsx">
          <Image src="/images/icons/algo_home.png" alt="" width={324} height={387} data-sentry-element="Image" data-sentry-source-file="AlgoHome.tsx" />
        </GridItem>
      </Grid>
    </Container>;
};
export default AlgoHome;