import { Box, Container, Grid, GridItem, Image, Show, Text } from "@chakra-ui/react";
const HowTo = () => {
  return <>
      <Container variant="responsiveContainer" data-sentry-element="Container" data-sentry-source-file="HowTo.tsx">
        <Grid templateColumns={{
        base: "1fr",
        md: "repeat(3, 1fr)"
      }} gap={{
        base: "10",
        md: "24"
      }} data-sentry-element="Grid" data-sentry-source-file="HowTo.tsx">
          <GridItem position="relative" width={{
          base: "200px",
          md: "auto"
        }} mx="auto" data-sentry-element="GridItem" data-sentry-source-file="HowTo.tsx">
            <Image fetchPriority="low" src="/images/howto1.svg" alt="" zIndex="2" position="inherit" data-sentry-element="Image" data-sentry-source-file="HowTo.tsx" />
            <Show above="md" data-sentry-element="Show" data-sentry-source-file="HowTo.tsx">
              <Image fetchPriority="low" src="/images/howtoline1.svg" alt="" position="absolute" top="85px" left="-162px" data-sentry-element="Image" data-sentry-source-file="HowTo.tsx" />
            </Show>
            <Box data-sentry-element="Box" data-sentry-source-file="HowTo.tsx">
              <Text as="h3" fontSize="1.25rem" fontWeight="500" mb="3" data-sentry-element="Text" data-sentry-source-file="HowTo.tsx">
                Le job de vos rêves
              </Text>
              <Text as="p" data-sentry-element="Text" data-sentry-source-file="HowTo.tsx">Renseignez le métier que vous souhaitez faire et la localisation (Ville ou Code postal)</Text>
            </Box>
          </GridItem>
          <GridItem position="relative" width={{
          base: "200px",
          md: "auto"
        }} mx="auto" data-sentry-element="GridItem" data-sentry-source-file="HowTo.tsx">
            <Image fetchPriority="low" src="/images/howto2.svg" alt="" zIndex="2" position="inherit" data-sentry-element="Image" data-sentry-source-file="HowTo.tsx" />
            <Show above="md" data-sentry-element="Show" data-sentry-source-file="HowTo.tsx">
              <Image fetchPriority="low" src="/images/howtoline2a.svg" alt="" position="absolute" top="47px" left="-208px" data-sentry-element="Image" data-sentry-source-file="HowTo.tsx" />
            </Show>
            <Box data-sentry-element="Box" data-sentry-source-file="HowTo.tsx">
              <Text as="h3" fontSize="1.25rem" fontWeight="500" mb="3" data-sentry-element="Text" data-sentry-source-file="HowTo.tsx">
                En un clin d’&oelig;il
              </Text>
              <Text as="p" data-sentry-element="Text" data-sentry-source-file="HowTo.tsx">Obtenez la liste des formations et entreprises où réaliser votre alternance</Text>
            </Box>
          </GridItem>
          <GridItem position="relative" width={{
          base: "200px",
          md: "auto"
        }} mx="auto" mb="12" data-sentry-element="GridItem" data-sentry-source-file="HowTo.tsx">
            <Image fetchPriority="low" src="/images/howto3.svg" alt="" zIndex="2" position="inherit" data-sentry-element="Image" data-sentry-source-file="HowTo.tsx" />
            <Show above="md" data-sentry-element="Show" data-sentry-source-file="HowTo.tsx">
              <Image fetchPriority="low" src="/images/howtoline3a.svg" alt="" position="absolute" top="47px" left="-200px" data-sentry-element="Image" data-sentry-source-file="HowTo.tsx" />
              <Image fetchPriority="low" src="/images/howtoline3b.svg" alt="" position="absolute" top="47px" left="158px" data-sentry-element="Image" data-sentry-source-file="HowTo.tsx" />
            </Show>
            <Box data-sentry-element="Box" data-sentry-source-file="HowTo.tsx">
              <Text as="h3" fontSize="1.25rem" fontWeight="500" mb="3" data-sentry-element="Text" data-sentry-source-file="HowTo.tsx">
                Un contact facile
              </Text>
              <Text as="p" data-sentry-element="Text" data-sentry-source-file="HowTo.tsx">Contactez facilement les centres de formation ou les entreprises pour postuler</Text>
            </Box>
          </GridItem>
        </Grid>
      </Container>
    </>;
};
export default HowTo;