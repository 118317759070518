import { CloseIcon, HamburgerIcon, LockIcon } from "@chakra-ui/icons";
import { Box, Container, Flex, Image, Stack, useDisclosure } from "@chakra-ui/react";
import NextLink from "next/link";
import { LbaNew } from "../theme/components/logos";
import InfoBanner from "./InfoBanner/InfoBanner";
const Pipe = () => <Box display={["none", "none", "none", "block"]} borderRight="1px solid" borderColor="grey.300" my="6" marginTop="10px !important;" marginBottom="10px !important;" data-sentry-element="Box" data-sentry-component="Pipe" data-sentry-source-file="navigation.tsx"></Box>;
const Navigation = ({
  currentPage = undefined
}) => {
  const {
    isOpen,
    onOpen,
    onClose
  } = useDisclosure();
  return <Box sx={["organisme-de-formation", "acces-recruteur"].includes(currentPage) ? {
    boxShadow: "0px 1px 8px rgba(8, 67, 85, 0.24)"
  } : {}} as="header" data-sentry-element="Box" data-sentry-component="Navigation" data-sentry-source-file="navigation.tsx">
      <InfoBanner data-sentry-element="InfoBanner" data-sentry-source-file="navigation.tsx" />
      <Container variant="responsiveContainer" data-sentry-element="Container" data-sentry-source-file="navigation.tsx">
        <Flex py={2} direction={["column", "column", "column", "row"]} justify="space-between" data-sentry-element="Flex" data-sentry-source-file="navigation.tsx">
          <Flex alignItems="center" wrap="wrap" data-sentry-element="Flex" data-sentry-source-file="navigation.tsx">
            <Flex flexGrow={1} data-sentry-element="Flex" data-sentry-source-file="navigation.tsx">
              <Flex alignItems="center" data-sentry-element="Flex" data-sentry-source-file="navigation.tsx">
                <NextLink href="/" aria-label="Retour à l'accueil" data-sentry-element="NextLink" data-sentry-source-file="navigation.tsx">
                  <Image src="/images/marianne.svg" aria-hidden={true} alt="" width="108" height="90" data-sentry-element="Image" data-sentry-source-file="navigation.tsx" />
                </NextLink>
                <NextLink href="/" aria-label="Retour à l'accueil" data-sentry-element="NextLink" data-sentry-source-file="navigation.tsx">
                  <LbaNew w="143px" h="37px" data-sentry-element="LbaNew" data-sentry-source-file="navigation.tsx" />
                </NextLink>
              </Flex>
            </Flex>
            <HamburgerIcon boxSize={6} onClick={isOpen ? onClose : onOpen} display={[isOpen ? "none" : "inline", isOpen ? "none" : "inline", isOpen ? "none" : "inline", "none"]} cursor="pointer" data-sentry-element="HamburgerIcon" data-sentry-source-file="navigation.tsx" />
            <CloseIcon boxSize={4} mr={1} onClick={isOpen ? onClose : onOpen} display={[isOpen ? "inline" : "none", isOpen ? "inline" : "none", isOpen ? "inline" : "none", "none"]} cursor="pointer" data-sentry-element="CloseIcon" data-sentry-source-file="navigation.tsx" />
          </Flex>
          <Box display={[isOpen ? "block" : "none", isOpen ? "block" : "none", isOpen ? "block" : "none", "block"]} data-sentry-element="Box" data-sentry-source-file="navigation.tsx">
            <Box display={["block", "block", "block", "flex"]} alignItems="center" height="100%" data-sentry-element="Box" data-sentry-source-file="navigation.tsx">
              <Stack align="left" direction={["column", "column", "column", "row"]} mb={[2, 2, 2, 0]} data-sentry-element="Stack" data-sentry-source-file="navigation.tsx">
                <NextLink href="/" aria-label="Accès espace candidat" data-sentry-element="NextLink" data-sentry-source-file="navigation.tsx">
                  <Box display="inline-grid" data-sentry-element="Box" data-sentry-source-file="navigation.tsx">
                    <Box as="span" ml={[0, 0, 0, 2]} mr="1" color="bluefrance.500" fontSize={14} pl={[1, 1, 1, 3]} pr={3} py={2} bg={!currentPage ? "#00000014" : "none"} data-sentry-element="Box" data-sentry-source-file="navigation.tsx">
                      Candidat
                    </Box>
                  </Box>
                </NextLink>
                <Pipe data-sentry-element="Pipe" data-sentry-source-file="navigation.tsx" />
                <NextLink href="/acces-recruteur" aria-label="Accès espace recruteur" data-sentry-element="NextLink" data-sentry-source-file="navigation.tsx">
                  <Box display="inline-grid" data-sentry-element="Box" data-sentry-source-file="navigation.tsx">
                    <Box as="span" ml={[0, 0, 0, 2]} mr="1" color="bluefrance.500" fontSize={14} pl={[1, 1, 1, 3]} pr={3} py={2} bg={currentPage === "acces-recruteur" ? "#00000014" : "none"} data-sentry-element="Box" data-sentry-source-file="navigation.tsx">
                      Recruteur
                    </Box>
                  </Box>
                </NextLink>
                <Pipe data-sentry-element="Pipe" data-sentry-source-file="navigation.tsx" />
                <NextLink href="/organisme-de-formation" aria-label="Accès espace organisme de formation" data-sentry-element="NextLink" data-sentry-source-file="navigation.tsx">
                  <Box display="inline-grid" data-sentry-element="Box" data-sentry-source-file="navigation.tsx">
                    <Box as="span" ml={[0, 0, 0, 2]} mr="1" color="bluefrance.500" fontSize={14} pl={[1, 1, 1, 3]} pr={3} py={2} bg={currentPage === "organisme-de-formation" ? "#00000014" : "none"} data-sentry-element="Box" data-sentry-source-file="navigation.tsx">
                      Organisme de formation
                    </Box>
                  </Box>
                </NextLink>
                {currentPage === "acces-recruteur" || currentPage === "organisme-de-formation" ? <>
                    <Pipe />
                    <NextLink href="/espace-pro/authentification" aria-label="Espace pro">
                      <Flex alignItems="center">
                        <LockIcon color="bluefrance.500" ml={{
                      base: 0,
                      lg: 3
                    }} />
                        <Box as="span" color="bluefrance.500" fontSize={14} pl={[1, 1, 1, 2]} pr={2} py={2}>
                          Connexion
                        </Box>
                      </Flex>
                    </NextLink>
                  </> : ""}
              </Stack>
            </Box>
          </Box>
        </Flex>
      </Container>
    </Box>;
};
export default Navigation;