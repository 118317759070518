import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Box, Grid, GridItem, Image, Link, Text } from "@chakra-ui/react";
import React from "react";
const AmeliorerLBA = () => {
  return <Box as="section" p="8" mb="12" backgroundColor="#f5f5fe" borderRadius="10px" data-sentry-element="Box" data-sentry-component="AmeliorerLBA" data-sentry-source-file="AmeliorerLBA.tsx">
      <Grid templateColumns={{
      base: "1fr",
      md: "1fr 1fr"
    }} data-sentry-element="Grid" data-sentry-source-file="AmeliorerLBA.tsx">
        <GridItem display="flex" justifyContent="center" alignItems="center" data-sentry-element="GridItem" data-sentry-source-file="AmeliorerLBA.tsx">
          <Image src="/images/home_pics/illu-support.svg" alt="" data-sentry-element="Image" data-sentry-source-file="AmeliorerLBA.tsx" />
        </GridItem>
        <GridItem pl="8" data-sentry-element="GridItem" data-sentry-source-file="AmeliorerLBA.tsx">
          <Text as="h2" color="#000091" fontSize="2rem" fontWeight="500" my="5" data-sentry-element="Text" data-sentry-source-file="AmeliorerLBA.tsx">
            Vous êtes une entreprise à la recherche d’alternants ?
          </Text>
          <Text data-sentry-element="Text" data-sentry-source-file="AmeliorerLBA.tsx">
            Participez à une démonstration de La bonne alternance. Un <strong>service public gratuit pour publier facilement vos offres sur plusieurs plateformes</strong> : La
            bonne alternance, 1jeune1solution, Parcoursup, l'Onisep, et bien d'autres.
          </Text>
          <Text data-sentry-element="Text" data-sentry-source-file="AmeliorerLBA.tsx">
            <strong>Chaque semaine</strong>, nous organisons des webinaires spécialement conçus pour les recruteurs afin de vous présenter toutes les fonctionnalités de notre
            plateforme.
          </Text>
          <Text data-sentry-element="Text" data-sentry-source-file="AmeliorerLBA.tsx">
            <strong>Profitez-en pour poser vos questions en direct !</strong>
          </Text>
          <Box mt="7" data-sentry-element="Box" data-sentry-source-file="AmeliorerLBA.tsx">
            <Link href="https://app.livestorm.co/la-bonne-alternance/premiers-pas-sur-la-bonne-alternance-maximisez-votre-experience" aria-label="M'inscrire au webinaire - nouvelle fenêtre" title="M'inscrire au webinaire" color="#000091" border="1px solid #000091" padding="10px 24px" isExternal data-sentry-element="Link" data-sentry-source-file="AmeliorerLBA.tsx">
              M'inscrire au webinaire <ExternalLinkIcon mx="2px" data-sentry-element="ExternalLinkIcon" data-sentry-source-file="AmeliorerLBA.tsx" />
            </Link>
          </Box>
          <Box mt="3" data-sentry-element="Box" data-sentry-source-file="AmeliorerLBA.tsx">&nbsp;</Box>
        </GridItem>
      </Grid>
    </Box>;
};
export default AmeliorerLBA;