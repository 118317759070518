import { Box, Text } from "@chakra-ui/react";
import SearchForm from "../SearchForm/SearchForm";
const WidgetHeaderHomePage = props => {
  return <Box boxShadow="0 4px 12px 2px rgb(0 0 0 / 21%)" pb={6} pt={[2, 2, 2, 6]} px={4} bg="white" backgroundClip="border-box" borderRadius="10px" data-sentry-element="Box" data-sentry-component="WidgetHeaderHomePage" data-sentry-source-file="WidgetHeaderHomePage.tsx">
      <Text mb={3} as="h1" fontSize={["26px", "29px"]} fontWeight={700} data-sentry-element="Text" data-sentry-source-file="WidgetHeaderHomePage.tsx">
        <Text as="span" data-sentry-element="Text" data-sentry-source-file="WidgetHeaderHomePage.tsx">Trouvez emploi et formation </Text>
        <Text as="span" color="info" data-sentry-element="Text" data-sentry-source-file="WidgetHeaderHomePage.tsx">
          en alternance
        </Text>
      </Text>
      <SearchForm {...props} data-sentry-element="SearchForm" data-sentry-source-file="WidgetHeaderHomePage.tsx" />
    </Box>;
};
export default WidgetHeaderHomePage;