import Head from "next/head";
import React from "react";
const HeadLaBonneAlternance = () => {
  return <Head data-sentry-element="Head" data-sentry-component="HeadLaBonneAlternance" data-sentry-source-file="head.tsx">
      <title>La bonne alternance | Trouvez votre alternance</title>
      <meta name="viewport" content="width=device-width,initial-scale=1,shrink-to-fit=no" data-sentry-element="meta" data-sentry-source-file="head.tsx" />
      <link rel="canonical" href="http://labonnealternance.apprentissage.beta.gouv.fr" />
      <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
      <link rel="manifest" href="/favicon/site.webmanifest" />

      <meta name="msapplication-config" content="/favicon/browserconfig.xml" data-sentry-element="meta" data-sentry-source-file="head.tsx" />
      <meta name="msapplication-TileColor" content="#ffffff" data-sentry-element="meta" data-sentry-source-file="head.tsx" />
      <meta name="theme-color" content="#ffffff" data-sentry-element="meta" data-sentry-source-file="head.tsx" />
      <meta id="robots-meta" name="robots" content="index, follow" data-sentry-element="meta" data-sentry-source-file="head.tsx" />
      <meta name="keywords" content="contrat offres alternance emploi formation apprentissage" data-sentry-element="meta" data-sentry-source-file="head.tsx" />
      <meta name="description" content="Vous ne trouvez pas de contrat ou d'offres d'alternance ? Essayez La bonne alternance ! Trouvez ici les formations en alternance et les entreprises qui recrutent régulièrement en alternance" data-sentry-element="meta" data-sentry-source-file="head.tsx" />
      <meta name="google-site-verification" content="neOTrE-YKZ9LbgLlaX8UkYN6MJTPlWpeotPQqbrJ19Q" data-sentry-element="meta" data-sentry-source-file="head.tsx" />
      <meta property="og:site_name" content="La bonne alternance" data-sentry-element="meta" data-sentry-source-file="head.tsx" />
      <meta property="og:title" content="La bonne alternance - Trouvez votre alternance" data-sentry-element="meta" data-sentry-source-file="head.tsx" />
      <meta property="og:type" content="site" data-sentry-element="meta" data-sentry-source-file="head.tsx" />
      <meta property="og:url" content="https://labonnealternance.apprentissage.beta.gouv.fr" data-sentry-element="meta" data-sentry-source-file="head.tsx" />
      <meta property="og:description" content="Vous ne trouvez pas de contrat ou d'offres d'alternance ? Essayez La bonne alternance ! Trouvez ici les formations en alternance et les entreprises qui recrutent régulièrement en alternance" data-sentry-element="meta" data-sentry-source-file="head.tsx" />
    </Head>;
};
export default HeadLaBonneAlternance;