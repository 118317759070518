import { Link as ChakraLink } from "@chakra-ui/react";
import NavLink from "next/link";
import React from "react";
const Link = ({
  children,
  href,
  shallow = false,
  ...rest
}) => {
  return <NavLink legacyBehavior href={href} passHref shallow={shallow} data-sentry-element="NavLink" data-sentry-component="Link" data-sentry-source-file="Link.tsx">
      <ChakraLink {...rest} data-sentry-element="ChakraLink" data-sentry-source-file="Link.tsx">{children}</ChakraLink>
    </NavLink>;
};
export default Link;