import { Image, Text } from "@chakra-ui/react";
import React from "react";
const tagProperties = {
  color: "pinksoft.600",
  background: "pinksoft.200"
};
const TagOffreEmploi = () => {
  return <Text as="span" variant="tag" {...tagProperties} data-sentry-element="Text" data-sentry-component="TagOffreEmploi" data-sentry-source-file="TagOffreEmploi.tsx">
      <Image width="16px" mb="-2px" src="/images/briefcase.svg" alt="" data-sentry-element="Image" data-sentry-source-file="TagOffreEmploi.tsx" />
      <Text whiteSpace="nowrap" as="span" ml={1} data-sentry-element="Text" data-sentry-source-file="TagOffreEmploi.tsx">
        Offre d&apos;emploi
      </Text>
    </Text>;
};
export default TagOffreEmploi;