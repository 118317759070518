import { Icon } from "@chakra-ui/react";
export function LbaNew(props) {
  return <Icon width="179px" height="66px" viewBox="0 0 352 91" {...props} data-sentry-element="Icon" data-sentry-component="LbaNew" data-sentry-source-file="LbaNew.tsx">
      <path d="M41.4 90.36C64.2646 90.36 82.8 82.7847 82.8 73.44C82.8 64.0954 64.2646 56.52 41.4 56.52C18.5354 56.52 0 64.0954 0 73.44C0 82.7847 18.5354 90.36 41.4 90.36Z" fill="#A558A0" data-sentry-element="path" data-sentry-source-file="LbaNew.tsx" />
      <path d="M41.4 0C33.7351 0.00530028 26.3859 3.05319 20.9669 8.474C15.548 13.8948 12.5026 21.2451 12.5 28.91C12.5 47.7 30.84 65.71 38.29 72.27C39.1523 73.0246 40.2592 73.4406 41.405 73.4406C42.5508 73.4406 43.6577 73.0246 44.52 72.27C52 65.6 70.31 47.7 70.31 28.91C70.3073 21.2434 67.2606 13.8916 61.8395 8.47047C56.4184 3.04937 49.0666 0.00265051 41.4 0V0ZM41.4 40.78C38.7877 40.778 36.2347 40.0016 34.0636 38.5489C31.8926 37.0961 30.201 35.0323 29.2027 32.6183C28.2044 30.2043 27.9442 27.5486 28.455 24.9867C28.9659 22.4249 30.2248 20.072 32.0727 18.2256C33.9205 16.3791 36.2743 15.122 38.8365 14.6131C41.3987 14.1042 44.0543 14.3664 46.4676 15.3665C48.8808 16.3666 50.9433 18.0598 52.3944 20.232C53.8455 22.4041 54.62 24.9577 54.62 27.57C54.6213 29.306 54.2802 31.0252 53.6162 32.6291C52.9521 34.2331 51.9783 35.6903 50.7503 36.9174C49.5223 38.1444 48.0643 39.1172 46.4599 39.78C44.8554 40.4428 43.136 40.7826 41.4 40.78Z" fill="#161616" data-sentry-element="path" data-sentry-source-file="LbaNew.tsx" />
      <path d="M113.54 5.51001H120.29V32.64H132.92V38.76H113.54V5.51001Z" fill="#161616" data-sentry-element="path" data-sentry-source-file="LbaNew.tsx" />
      <path d="M137.82 32.3001C137.82 28.6901 140.62 25.9901 145.75 25.1301L153.06 23.9001V23.2801C153.06 20.7601 151.16 19.1501 148.41 19.1501C147.344 19.1449 146.292 19.4022 145.349 19.8992C144.405 20.3962 143.599 21.1177 143 22.0001L138.59 18.6201C139.753 17.0836 141.269 15.8498 143.01 15.023C144.75 14.1962 146.664 13.8007 148.59 13.8701C155.52 13.8701 159.09 18.0101 159.09 23.2801V38.7601H153.09V36.4401C152.171 37.4138 151.058 38.184 149.823 38.7007C148.588 39.2175 147.258 39.4694 145.92 39.4401C141.09 39.4801 137.82 36.7201 137.82 32.3001ZM147.13 34.6801C148.311 34.7221 149.483 34.4499 150.525 33.8913C151.566 33.3326 152.441 32.5074 153.06 31.5001V28.1201L147.36 29.0701C144.85 29.5001 143.8 30.5501 143.8 32.0701C143.8 33.5901 145.08 34.6801 147.13 34.6801Z" fill="#161616" data-sentry-element="path" data-sentry-source-file="LbaNew.tsx" />
      <path d="M192.3 39.7099C190.924 39.7737 189.55 39.5496 188.265 39.052C186.98 38.5544 185.814 37.7941 184.84 36.8199V38.7599H178.84V3.13989H184.84V16.7699C185.813 15.7944 186.98 15.0326 188.264 14.5333C189.549 14.0339 190.923 13.808 192.3 13.8699C199.9 13.8699 204.46 19.8099 204.46 26.7899C204.46 33.7699 199.9 39.7099 192.3 39.7099ZM184.84 22.9999V30.5999C185.574 31.669 186.56 32.5405 187.711 33.137C188.863 33.7336 190.143 34.0369 191.44 34.0199C195.53 34.0199 198.19 31.0199 198.19 26.7999C198.19 22.5799 195.53 19.5799 191.44 19.5799C190.141 19.5475 188.854 19.844 187.701 20.442C186.547 21.0399 185.563 21.9198 184.84 22.9999Z" fill="#161616" data-sentry-element="path" data-sentry-source-file="LbaNew.tsx" />
      <path d="M234.48 26.7899C234.48 30.2669 233.099 33.6015 230.64 36.0601C228.182 38.5187 224.847 39.8999 221.37 39.8999C217.893 39.8999 214.558 38.5187 212.1 36.0601C209.641 33.6015 208.26 30.2669 208.26 26.7899C208.26 23.3129 209.641 19.9784 212.1 17.5198C214.558 15.0612 217.893 13.6799 221.37 13.6799C224.847 13.6799 228.182 15.0612 230.64 17.5198C233.099 19.9784 234.48 23.3129 234.48 26.7899ZM228.21 26.7899C228.21 22.6099 225.31 19.5699 221.46 19.5699C220.522 19.5591 219.592 19.7406 218.727 20.1034C217.862 20.4662 217.081 21.0025 216.431 21.6791C215.782 22.3558 215.278 23.1584 214.951 24.0375C214.624 24.9165 214.481 25.8533 214.53 26.7899C214.482 27.7257 214.627 28.6614 214.954 29.5393C215.282 30.4172 215.786 31.2186 216.435 31.8942C217.085 32.5697 217.865 33.1051 218.73 33.4673C219.594 33.8294 220.523 34.0107 221.46 33.9999C225.31 33.9999 228.21 30.9299 228.21 26.7899Z" fill="#161616" data-sentry-element="path" data-sentry-source-file="LbaNew.tsx" />
      <path d="M240 14.8199H246V16.7199C246.927 15.7922 248.032 15.0615 249.249 14.5718C250.466 14.082 251.769 13.8433 253.08 13.8699C258.25 13.8699 262.39 17.4399 262.39 24.5099V38.7599H256.31V24.7599C256.31 21.5799 254.5 19.5799 251.56 19.5799C250.396 19.6087 249.263 19.9558 248.283 20.5834C247.303 21.211 246.513 22.0952 246 23.1399V38.7599H240V14.8199Z" fill="#161616" data-sentry-element="path" data-sentry-source-file="LbaNew.tsx" />
      <path d="M269.25 14.82H275.25V16.72C276.178 15.7932 277.283 15.0632 278.5 14.5735C279.716 14.0838 281.019 13.8445 282.33 13.87C287.5 13.87 291.64 17.44 291.64 24.51V38.76H285.56V24.76C285.56 21.58 283.75 19.58 280.81 19.58C279.645 19.6098 278.511 19.9583 277.531 20.5877C276.551 21.2172 275.762 22.1034 275.25 23.15V38.76H269.25V14.82Z" fill="#161616" data-sentry-element="path" data-sentry-source-file="LbaNew.tsx" />
      <path d="M310.1 39.7101C301.59 39.7101 296.8 33.7801 296.8 26.7901C296.8 19.8001 301.26 13.8701 309.1 13.8701C315.75 13.8701 320.1 18.3901 320.1 24.6601C320.098 25.794 319.971 26.9242 319.72 28.0301H303C303.57 32.4901 306.33 34.3001 310.08 34.3001C311.259 34.3296 312.43 34.088 313.501 33.5939C314.573 33.0998 315.517 32.3664 316.26 31.4501L320.58 34.8701C319.34 36.4474 317.743 37.7083 315.922 38.5496C314.1 39.3909 312.105 39.7886 310.1 39.7101ZM303.16 23.7101H314.16C314.147 23.0483 314.003 22.3956 313.736 21.7899C313.469 21.1843 313.084 20.6377 312.604 20.1818C312.124 19.726 311.558 19.37 310.94 19.1346C310.321 18.8991 309.662 18.7888 309 18.8101C305.87 18.8101 303.83 20.5201 303.16 23.6601V23.7101Z" fill="#161616" data-sentry-element="path" data-sentry-source-file="LbaNew.tsx" />
      <path d="M110.93 80.3001C110.93 76.6901 113.74 73.9901 118.86 73.1301L126.18 71.9001V71.2801C126.18 68.7601 124.28 67.1501 121.53 67.1501C120.462 67.1424 119.408 67.3985 118.463 67.8956C117.517 68.3928 116.709 69.1157 116.11 70.0001L111.69 66.6201C112.853 65.0836 114.369 63.8498 116.109 63.023C117.85 62.1962 119.764 61.8007 121.69 61.8701C128.63 61.8701 132.19 66.0101 132.19 71.2801V86.7601H126.19V84.4401C125.271 85.4138 124.158 86.184 122.923 86.7007C121.688 87.2175 120.358 87.4694 119.02 87.4401C114.21 87.4801 110.93 84.7201 110.93 80.3001ZM120.24 82.6801C121.423 82.7219 122.595 82.4496 123.639 81.8911C124.682 81.3325 125.559 80.5075 126.18 79.5001V76.1201L120.48 77.0701C117.96 77.5001 116.92 78.5501 116.92 80.0701C116.92 81.5901 118.2 82.6801 120.24 82.6801Z" fill="#161616" data-sentry-element="path" data-sentry-source-file="LbaNew.tsx" />
      <path d="M139.1 51.1399H145.1V86.7599H139.1V51.1399Z" fill="#161616" data-sentry-element="path" data-sentry-source-file="LbaNew.tsx" />
      <path d="M153.87 68.2401H149.41V62.8201H153.87V56.8201H160V62.8201H167.32V68.2401H160V78.0001C160 80.6601 161.43 81.7101 163.8 81.7101C165.009 81.7678 166.218 81.5912 167.36 81.1901V86.4601C165.897 87.033 164.33 87.2919 162.76 87.2201C156.76 87.2201 153.92 83.8501 153.92 78.0001L153.87 68.2401Z" fill="#161616" data-sentry-element="path" data-sentry-source-file="LbaNew.tsx" />
      <path d="M183.8 87.7101C175.29 87.7101 170.5 81.7801 170.5 74.7901C170.5 67.8001 175 61.8701 182.8 61.8701C189.45 61.8701 193.8 66.3901 193.8 72.6601C193.798 73.794 193.671 74.9242 193.42 76.0301H176.67C177.24 80.4901 180 82.3001 183.75 82.3001C184.928 82.3311 186.098 82.0901 187.168 81.5958C188.238 81.1016 189.18 80.3673 189.92 79.4501L194.25 82.8701C193.012 84.4426 191.421 85.7006 189.605 86.5416C187.789 87.3826 185.8 87.783 183.8 87.7101ZM176.86 71.7101H187.86C187.841 71.0473 187.69 70.3949 187.416 69.791C187.142 69.187 186.751 68.6436 186.265 68.1922C185.78 67.7409 185.209 67.3907 184.587 67.162C183.964 66.9333 183.302 66.8307 182.64 66.8601C179.57 66.8101 177.53 68.5201 176.86 71.6601V71.7101Z" fill="#161616" data-sentry-element="path" data-sentry-source-file="LbaNew.tsx" />
      <path d="M199.71 62.8199H205.71V65.1999C206.508 64.3118 207.482 63.5997 208.57 63.109C209.658 62.6183 210.836 62.3597 212.03 62.3499C212.74 62.3475 213.445 62.4589 214.12 62.6799V68.6199C213.251 68.3958 212.357 68.2849 211.46 68.2899C210.311 68.2583 209.174 68.5312 208.164 69.0809C207.154 69.6306 206.307 70.4375 205.71 71.4199V86.7599H199.71V62.8199Z" fill="#161616" data-sentry-element="path" data-sentry-source-file="LbaNew.tsx" />
      <path d="M219 62.82H225V64.72C225.926 63.7935 227.029 63.0635 228.244 62.5738C229.459 62.0841 230.76 61.8447 232.07 61.87C237.25 61.87 241.38 65.44 241.38 72.51V86.76H235.3V72.76C235.3 69.58 233.5 67.58 230.55 67.58C229.388 67.6084 228.255 67.9554 227.277 68.5832C226.298 69.2109 225.511 70.0953 225 71.14V86.76H219V62.82Z" fill="#161616" data-sentry-element="path" data-sentry-source-file="LbaNew.tsx" />
      <path d="M246.92 80.3001C246.92 76.6901 249.73 73.9901 254.86 73.1301L262.17 71.9001V71.2801C262.17 68.7601 260.27 67.1501 257.52 67.1501C256.452 67.1424 255.398 67.3985 254.453 67.8956C253.507 68.3928 252.699 69.1157 252.1 70.0001L247.68 66.6201C248.843 65.0836 250.359 63.8498 252.099 63.023C253.84 62.1962 255.754 61.8007 257.68 61.8701C264.62 61.8701 268.18 66.0101 268.18 71.2801V86.7601H262.18V84.4401C261.261 85.4138 260.148 86.184 258.913 86.7007C257.678 87.2175 256.348 87.4694 255.01 87.4401C250.2 87.4801 246.92 84.7201 246.92 80.3001ZM256.23 82.6801C257.413 82.7219 258.585 82.4496 259.629 81.8911C260.672 81.3325 261.549 80.5075 262.17 79.5001V76.1201L256.47 77.0701C253.95 77.5001 252.91 78.5501 252.91 80.0701C252.91 81.5901 254.19 82.6801 256.23 82.6801Z" fill="#161616" data-sentry-element="path" data-sentry-source-file="LbaNew.tsx" />
      <path d="M275.09 62.82H281.09V64.72C282.018 63.7932 283.123 63.0632 284.34 62.5735C285.556 62.0838 286.859 61.8445 288.17 61.87C293.35 61.87 297.48 65.44 297.48 72.51V86.76H291.4V72.76C291.4 69.58 289.59 67.58 286.65 67.58C285.485 67.6098 284.351 67.9583 283.371 68.5877C282.391 69.2172 281.602 70.1034 281.09 71.15V86.76H275.09V62.82Z" fill="#161616" data-sentry-element="path" data-sentry-source-file="LbaNew.tsx" />
      <path d="M321.26 79.2599L325.96 82.8699C324.773 84.4164 323.238 85.6609 321.48 86.5019C319.721 87.343 317.789 87.7569 315.84 87.7099C307.58 87.7099 302.64 81.7799 302.64 74.7899C302.64 67.7999 307.58 61.8699 315.84 61.8699C317.797 61.8161 319.738 62.2278 321.504 63.071C323.271 63.9142 324.811 65.1648 326 66.7199L321.3 70.3299C320.684 69.4473 319.858 68.7325 318.895 68.2505C317.933 67.7685 316.866 67.5346 315.79 67.5699C311.85 67.5699 308.95 70.5699 308.95 74.7899C308.95 79.0099 311.85 82.0099 315.88 82.0099C316.934 82.03 317.976 81.7895 318.915 81.3098C319.854 80.83 320.659 80.1259 321.26 79.2599Z" fill="#161616" data-sentry-element="path" data-sentry-source-file="LbaNew.tsx" />
      <path d="M341.11 87.7101C332.61 87.7101 327.82 81.7801 327.82 74.7901C327.82 67.8001 332.28 61.8701 340.12 61.8701C346.77 61.8701 351.12 66.3901 351.12 72.6601C351.118 73.794 350.99 74.9242 350.74 76.0301H334C334.57 80.4901 337.33 82.3001 341.08 82.3001C342.258 82.3311 343.428 82.0901 344.498 81.5958C345.568 81.1016 346.51 80.3673 347.25 79.4501L351.58 82.8701C350.34 84.4448 348.745 85.704 346.925 86.5451C345.106 87.3862 343.113 87.7854 341.11 87.7101ZM334.18 71.7101H345.18C345.167 71.0465 345.022 70.3922 344.754 69.7851C344.486 69.1781 344.099 68.6306 343.617 68.1745C343.135 67.7184 342.567 67.3629 341.946 67.1286C341.325 66.8944 340.663 66.7861 340 66.8101C336.89 66.8101 334.84 68.5201 334.18 71.6601V71.7101Z" fill="#161616" data-sentry-element="path" data-sentry-source-file="LbaNew.tsx" />
    </Icon>;
}