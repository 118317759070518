import { useRouter } from "next/router";
import { useEffect } from "react";
import { setZodLanguage } from "shared/helpers/zodWithOpenApi";
import { setIsTrackingEnabled, setTrackingCookies } from "@/tracking/trackingCookieUtils";
import { initMatomo } from "@/tracking/trackingMatomo";
import HeadLaBonneAlternance from "../components/head";
import PageTracker from "../components/pageTracker";
import Providers from "../context/Providers";
import "../public/styles/application.css";
import "../public/styles/fonts.css";
import "../public/styles/notion.css";
import "../styles/search.css";
export default function LaBonneAlternance({
  Component,
  pageProps
}) {
  const router = useRouter();
  useEffect(() => {
    setZodLanguage("fr");
    initMatomo();
    setIsTrackingEnabled();
  }, []);
  useEffect(() => {
    setTrackingCookies(router);
  }, [router.query]);
  return <Providers data-sentry-element="Providers" data-sentry-component="LaBonneAlternance" data-sentry-source-file="_app.tsx">
      <PageTracker data-sentry-element="PageTracker" data-sentry-source-file="_app.tsx">
        <HeadLaBonneAlternance data-sentry-element="HeadLaBonneAlternance" data-sentry-source-file="_app.tsx" />
        <Component {...pageProps} data-sentry-element="Component" data-sentry-source-file="_app.tsx" />
      </PageTracker>
    </Providers>;
}